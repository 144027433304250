@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.homePage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;

  &__text {
    font-size: 2rem;
    text-align: center;
    line-height: 3rem;
    margin-bottom: 3rem;
    font-variant: small-caps;

    @include mobile-design {
      max-width: 90%;
    }
  }

  &__gif-wrapper {
    border: 2px solid black;
    padding: 1rem 2rem;
    @include mobile-design {
      max-width: 90%;
    }

    .homePage__gif {
      height: auto;
      width: 40rem;
    }
  }

  &__logo {
    width: 50rem;
    margin: 2rem 0 4rem;

    @include mobile-design {
      max-width: 90%;
    }
  }

  &__announce-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    border-radius: 12px;
    padding: 0.5rem 2.2rem;
    height: fit-content;

    @include mobile-design {
      max-width: 90%;
    }

    .homePage__announce {
      text-transform: uppercase;
      color: white;
      font-size: 3rem;
      line-height: 4rem;
      letter-spacing: 9px;
      text-align: center;

      @include mobile-design {
        font-size: 2rem;
        line-height: 3rem;
      }
    }
  }
}
