@font-face {
  font-family: PlayFair;
  src: local(PlayFair),
    url("../../assets/fonts/PlayfairDisplay-VariableFont_wght.ttf");
}

@font-face {
  font-family: PlayFairItalic;
  src: local(PlayFairItalic),
    url("../../assets/fonts/PlayfairDisplay-Italic-VariableFont_wght.ttf");
}
