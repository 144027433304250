@use "./partials/resets";
@use "./partials/variables" as *;
@use "./partials/mixins" as *;
@use "./partials/fonts";

body {
  font-family: PlayFair, serif;
}

h1,
h2,
h3 {
  text-transform: capitalize;
}

h2 {
  font-size: 4rem;

  @include tablet-design {
    font-size: 3.6rem;
  }
}

h3 {
  font-size: 3.3rem;

  @include tablet-design {
    font-size: 2.9rem;
  }
}

p {
  line-height: 2.2rem;
}
